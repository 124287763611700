import React, { useState } from 'react'
import { MoreVert } from '@mui/icons-material'
import { 
    Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Table as TableLayout, IconButton, Menu, MenuItem 
} from '@mui/material'
import { t } from '@lingui/macro'

const Header = ({ headers = [], isMobile = false, actionsNumber = 0 }) => (
    <TableHead>
        <TableRow sx={{ display: 'flex' }}>
            { headers.map( (header, index) => (
                <TableCell  
                    key={header} 
                    align={ isMobile && header === t`Status` ? 'center' : 'left'} 
                    sx={[{ 
                            flex: 1, 
                            fontSize: '1em', 
                            backgroundColor: '#F0F0F0' 
                        }, 
                        !isMobile && index === headers.length -1 && {
                            flex: 0, 
                            paddingInlineEnd: `${(actionsNumber * 6)  + (actionsNumber === 1 ? 0.5 : 7.2)}rem`
                        }]}>
                            {header}
                </TableCell>
            )) }
        </TableRow>
    </TableHead>
)

const DesktopActions = ({
        item = {},
        actions = [] 
    }) => (
        <>
            {actions.map(({ text = '', Icon, onClick = () => null }) => (
                    <Button
                        key={ text } 
                        variant='outlined'
                        disabled={text === t`Disconnect` && item.status === 'disconnected'}
                        startIcon={ <Icon /> }
                        onClick={ () => onClick(item) }
                        sx={{ paddingInline: '2rem', marginInlineEnd: '1rem' }}>
                            { text }
                    </Button>
            ))}

        </>
)

const MobileActions = ({
    item ={},
    actions = []
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const onClose = (onClick = () => null) => {
        onClick(item)
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton
                id="basic-button"
                aria-haspopup="true"
                aria-expanded={ open ? 'true' : undefined } 
                aria-controls={ open ? 'basic-menu' : undefined }
                    onClick={ event => setAnchorEl(event.currentTarget) }>
                    <MoreVert color='black' />
            </IconButton>
            <Menu 
                open={ open } 
                anchorEl={ anchorEl }
                sx={{ padding: 0 }}
                onClose={ () => onClose() }
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                    { actions.map(({ text = '', onClick = () => null }) => (
                        <MenuItem 
                            key={ text }
                            sx={{ minWidth: '8rem' }}
                            disabled={text === t`Disconnect` && item.status === 'disconnected'}
                            onClick={() => onClose(onClick) }>
                                { text }
                        </MenuItem>
                    ))}
            </Menu>
        </>
    )
}

const Row = ({
    item = {},
    actions = [],
    isMobile = false,
    dataStructure = []
}) => {
    const Actions = isMobile ? MobileActions : DesktopActions
    return (
        <TableRow sx={{ display: 'flex' }}>
            {dataStructure.map(({ key, func }) => ( 
                <TableCell 
                    key={key} 
                    sx={{ fontSize: '1em', display: 'flex', flex: 1 }}
                    align={ isMobile && !!func ? 'center' : 'inherit' } >
                        {!!func ? func(item[key]) : item[key]}
                </TableCell>
            ))}
            <TableCell align='left' sx={{
                display: 'flex',
                flex: isMobile ? 1 : 0,
                justifyContent: isMobile ? "flex-end" : ""
            }}>
                {<Actions actions={actions} item={item} />}
            </TableCell>
        </TableRow>
    )}

const Body = ({
    data = [],
    search = '',
    actions = [],
    isMobile = false,
    strictSearch = '',
    dataStructure = []
}) => (
    <TableBody sx={{ overflow: 'auto' }} >
        { data
        .filter(item => {
            if (!search && !strictSearch) return true
            for (const { key } of dataStructure){
                if (strictSearch && typeof item[key] === "string" && item[key] === strictSearch) return true
                if (search && typeof item[key] === "string" && item[key].includes(search)) return true
            }
            return false
        }).map((item, index) => (
            <Row 
                item={item} 
                key={index} 
                actions={actions} 
                isMobile={isMobile} 
                dataStructure={dataStructure} />
        ))}
    </TableBody>
)

export const Table = ({
    data = [],
    search = '',
    actions = null,
    isMobile = false,
    strictSearch = '', 
    dataStructure = [],
}) => {
    let headers = Array.from(dataStructure, item => item.header)
    if(!!actions) headers.push(isMobile ? '' : t`Actions`)

  return (
    <TableContainer sx={{ marginBlockEnd: '4rem' }}>
        <TableLayout stickyHeader>
            <Header headers={headers} isMobile={isMobile} actionsNumber={actions.length} />
            <Body 
                data={data} 
                search={search}
                strictSearch={strictSearch}
                actions={actions}
                isMobile={isMobile} 
                dataStructure={dataStructure} />
        </TableLayout>
    </TableContainer>
  )
}
