
import { removeCookie, setCookie } from '../../helpers'
import { adminApi, authApi } from '../../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const adminLogin = createAsyncThunk('auth/admin', async (data = {}, thunkAPI) => {
    try {
        const token = await authApi.adminLogin(data)
        return { token, ...data }
    } catch (err) {
        return thunkAPI.rejectWithValue(err)
    }
})

const getAdmin = createAsyncThunk('admins/', async (_, thunkAPI) => {
    try {
        const data = await adminApi.getAdmin()
        return data
    } catch (err) {
        console.log(err);
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const deleteAdmin = createAsyncThunk('admins/id', async (id, thunkAPI) => {
    try {
        const data = await adminApi.deleteAdmin(id)
        return data
    } catch (err) {
        console.log(err);
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const createAdmin = createAsyncThunk('admins/create', async (form, thunkAPI) => {
    try {
        const data = await adminApi.createAdmin(form)
        return data
    } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const updateAdmin = createAsyncThunk('admins/update', async (form, thunkAPI) => {
    try {
        const data = await adminApi.updateAdmin(form)
        return data
    } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const getAllAdmins = createAsyncThunk('admins/all', async (_, thunkAPI) => {
    try {
        const data = await adminApi.getAllAdmins()
        return data
    } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const initialState = {
    email: '',
    admins: [],
    newEmail: '',
    loginError: ""
}

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        clearEmail: state => {
            state.email = ''
        },
        clearNewEmail: state => {
            state.newEmail = ''
        },
        logout: state => {
            state = initialState;
            removeCookie("role")
            removeCookie("token")
            return state
        }
    },
    extraReducers: builder => {
        builder.addCase(adminLogin.fulfilled, (state, action) => {
            const { token, email } = action.payload
            state.email = email
            state.loginError = ''
            if (!!token) {
                setCookie('token', token)
                setCookie('role', "admin")
            }
        })
        builder.addCase(adminLogin.rejected, (state, action) => {
            state.loginError = "שם משתמש או סיסמה שגויים"
        })
        builder.addCase(getAdmin.fulfilled, (state, action) => {
            state.email = action.payload.email
        })
        builder.addCase(getAdmin.rejected, (state, action) => {
            removeCookie('token')
            removeCookie('role')
            state.email = null
        })
        builder.addCase(getAllAdmins.fulfilled, (state, action) => {
            state.admins = action.payload
        })
        builder.addCase(createAdmin.fulfilled, (state, action) => {
            state.admins.push(action.payload)
        })
        builder.addCase(createAdmin.rejected, (state, action) => {
            state.error = action.payload
        })
        builder.addCase(updateAdmin.fulfilled, (state, action) => {
            const index = state.admins.findIndex(admin => admin._id === action.payload._id)
            if (index !== -1) {
                state.admins[index] = action.payload
            }
        })
        builder.addCase(updateAdmin.rejected, (state, action) => {
            state.error = action.payload
        })
        builder.addCase(deleteAdmin.fulfilled, (state, action) => {
        })
        builder.addCase(deleteAdmin.rejected, (state, action) => {
        })
    }
})

export const adminActions = {
    getAdmin,
    adminLogin,
    createAdmin,
    updateAdmin,
    deleteAdmin,
    getAllAdmins,
    ...adminSlice.actions
}

export default adminSlice.reducer