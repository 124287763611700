import { getCookie } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from "react"
import { adminActions } from "../../redux/actions.js"
import { useSelector, useDispatch } from 'react-redux'
import { Container, InputAdornment } from '@mui/material'
import { LoginForm, ShowPasswordButton, useNotification } from '../../components'
import { t } from '@lingui/macro'

export const AdminLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showNotification } = useNotification()
    const [password, setPassword] = useState('')
    const [ username, setUsername ] = useState('')
    const { email } = useSelector(state => state.admin)
    const { isMobile } = useSelector(state => state.base)
    const [showPassword, setShowPassword] = useState(true)

    useEffect(() => {
        if(email || getCookie('token')) navigate('/admin/monitors')
    }, [email, navigate])
    
    const onSubmit = async () => {
        const result = await dispatch(adminActions.adminLogin({ email: username, password }))
        if (result?.error) {
            showNotification(t`Login Failed`, "error")
            return
        }
    }

    const labelFontSize = isMobile ? '1em' : '1.3em' 
    const currentWidth = isMobile ? '22rem' :'30rem'

    const inputs = [
        {
            label: t`Email`,
            value: username,
            onChange: value => setUsername(value),
            inputSx: { width: currentWidth },
            labelStyle: { fontSize: labelFontSize }
        },
        {
            label: t`Password`,
            value: password,
            onChange: value => setPassword(value),
            labelStyle:{ marginTop: 10, fontSize: labelFontSize },
            inputSx: { width: currentWidth },
            type: showPassword ? "text" : "password",
            InputProps: { endAdornment: (
                <InputAdornment position="end">
                  <ShowPasswordButton
                    show={showPassword}
                    onClick={() => setShowPassword(!showPassword)}
                   />
                </InputAdornment>
            )}
        }
    ]

    return (
        <Container maxWidth="xl">
            <LoginForm
                title={t`Please enter email and password to log in to the management system`}
                inputs={inputs} 
                buttonText={t`Login`}
                onSubmit={onSubmit}
                buttonSx={{ width: currentWidth, fontSize: labelFontSize }}/>
        </Container>
    )
}

