import { getCookie } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import { Box, FormControl, Typography } from '@mui/material'
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { ScreenLayout } from '../../components/layouts'
import { patientActions } from '../../redux/slices/patientSlice'
import { LabelInput, TextAreaField, useNotification } from '../../components'
import { Trans, t } from '@lingui/macro'

const PatientProfileComponent = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification, showDialogMessage } = useNotification();
  const { isMobile } = useSelector(state => state.base)
  const { profile = null, error, saveProfileChanges = false } = useSelector(state => state.patient)
  const [name, setName] = useState('')
  const [birthCountry, setBirthCountry] = useState('')
  const [family, setFamily] = useState('')
  const [profession, setProfession] = useState('')
  const [hobbies, setHobbies] = useState('')
  const [gossip, setGossip] = useState('')
  const [landmarks, setLandmarks] = useState('')
  const connectionId = getCookie("connectionId")

  const handleSubmit = async () => {
    const data = {
      name,
      birthCountry,
      family,
      profession,
      hobbies,
      gossip,
      landmarks,
      connectionId
    }
    const result = await dispatch(patientActions.saveProfile(data))
    if (result?.error) {
      showNotification(t`An error occurred while saving the profile`, "error")
      return
    }
    showNotification(t`The profile was saved successfully`, "success")
    navigate("/patient/posts")
  }

  useEffect(() => {
    if (!saveProfileChanges) return;
    handleSubmit()
  }, [saveProfileChanges])

  useEffect(() => {
    if (!profile) return
    const {
      name,
      birthCountry,
      family,
      profession,
      hobbies,
      gossip,
      landmarks } = profile

    setName(name)
    setBirthCountry(birthCountry)
    setFamily(family)
    setProfession(profession)
    setHobbies(hobbies)
    setGossip(gossip)
    setLandmarks(landmarks)
  }, [profile])

  useEffect(() => {
    if (!getCookie('token') || error === "Forbidden") {
      showDialogMessage(t`You have been disconnected from the system. Please scan the QR code again.`)
      return navigate("/")
    }
    if (error) return
    dispatch(patientActions.getProfile({ connectionId }))
  }, [error])

  const labelFontSize = isMobile ? '1em' : '1.3em'
  const currentWidth = isMobile ? "calc(100%)" : "30rem";
  const textAreaMinHeight = isMobile ? 86 : "unset"

  const inputs = [
    {
      label: <Trans>My name is</Trans>,
      value: name,
      onChange: value => setName(value),
      labelStyle: { fontSize: labelFontSize, marginTop: 10 },
      inputSx: { width: currentWidth }
    },
    {
      label: <Trans>I was born in</Trans>,
      value: birthCountry,
      onChange: value => setBirthCountry(value),
      labelStyle: { fontSize: labelFontSize, marginTop: 10 },
      inputSx: { width: currentWidth }
    },
    {
      label: <Trans>My family and roots are</Trans>,
      value: family,
      onChange: value => setFamily(value),
      labelStyle: { fontSize: labelFontSize, marginTop: 10 },
      inputSx: { width: currentWidth }
    },
    {
      label: <Trans>I work in</Trans>,
      value: profession,
      onChange: value => setProfession(value),
      labelStyle: { fontSize: labelFontSize, marginTop: 10 },
      inputSx: { width: currentWidth }
    },
    {
      label: <Trans>My hobbies are</Trans>,
      value: hobbies,
      onChange: value => setHobbies(value),
      labelStyle: { fontSize: labelFontSize, marginTop: 10 },
      inputSx: { width: currentWidth }
    },
    {
      label: <Trans>Something interesting about me is</Trans>,
      value: gossip,
      inputType: "textarea",
      fullWidth: true,
      onChange: value => setGossip(value),
      labelStyle: { fontSize: labelFontSize, marginTop: 10 },
      inputSx: { width: currentWidth },
      InputProps: { disableUnderline: true },
      inputContainerStyle: {
        width: currentWidth,
      },
      maxLength: 200,
      mainContainerStyle: { minHeight: textAreaMinHeight },
    },
    {
      inputType: "textarea",
      fullWidth: true,
      label: <Trans>I have achieved in my life</Trans>,
      value: landmarks,
      onChange: value => setLandmarks(value),
      labelStyle: { fontSize: labelFontSize, marginTop: 20 },
      inputSx: { width: currentWidth },
      InputProps: { disableUnderline: true },
      inputContainerStyle: {
        width: currentWidth,
      },
      mainContainerStyle: { marginTop: 30, minHeight: textAreaMinHeight },
      maxLength: 200
    }
  ]
  const inputTypes = {
    input: ({ onChange, ...input }) => <LabelInput key={input.label}  {...input} onChange={(val) => { onChange(val); dispatch(patientActions.setProfileHasChanges(true)) }} />,
    textarea: ({ onChange, ...input }) => <TextAreaField
      key={input.label} {...input} onChange={(val) => { onChange(val); dispatch(patientActions.setProfileHasChanges(true)) }} />
  }
  
  return (
    <ScreenLayout
      isMobile={isMobile}
      buttonText={<Trans>Save</Trans>}
      buttonAlwaysBelow={true}
      onButtonClick={handleSubmit}
      containerSX={{ marginInline: '1rem', marginTop: 2 }}
      buttonContainerSx={{ justifyContent: "center" }}
      buttonSx={!isMobile && { maxWidth: currentWidth }}
    >
      <Box sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        gap: "3rem",
      }}>
        <FormControl style={{ maxHeight: "calc(100vh - 620px)", ...isMobile && { marginTop: 10, maxHeight: "calc(100vh - 715px)", width: "100%" } }}>
          <Typography fontSize='1.1em' maxWidth={"30rem"}><Trans>Please answer the questions as the patient so that we can get to know him better</Trans></Typography>
          {inputs.map(({ inputType = "input", ...input }) => inputTypes[inputType](input))}
        </FormControl>
      </Box>
    </ScreenLayout>
  )
}

export const PatientProfile = PatientProfileComponent
