// LanguageContext.js
import React, { createContext, useContext, useState } from 'react';
import { i18n } from "@lingui/core";
import { RTL_LANGUAGES } from '../enums';
import { getCookie, setCookie } from '../helpers';
import { messages as enMsgs } from "../locales/en/messages";
import { messages as heMsgs } from "../locales/he/messages";
import { messages as arMsgs } from "../locales/ar/messages";

i18n.load({
  en: enMsgs,
  he: heMsgs,
  ar: arMsgs
});

const lang = null //getCookie("language") //uncomment this to get language from cookie
const docLanguage = document.getElementsByTagName("html")[0].getAttribute("lang");
i18n.activate(lang || docLanguage);
const direction = RTL_LANGUAGES.includes(lang || docLanguage) ? "rtl" : "ltr"
document.getElementsByTagName("html")[0].dir = direction

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(lang || docLanguage);

  const changeLanguage = (val) => {
    i18n.activate(val);
    setLanguage(val);
    setCookie("language", val)

    const direction = RTL_LANGUAGES.includes(val) ? "rtl" : "ltr"
    document.getElementsByTagName("html")[0].dir = direction
    document.getElementsByTagName("html")[0].lang = val
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
