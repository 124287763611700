
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { patientActions } from '../../redux/actions'
import { getCookie } from '../../helpers'

export const PatientLogin = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { profile, connectionId } = useSelector(state => state.patient)
  const queryParams = new URLSearchParams(location.search);
  const connectionIdParam = queryParams.get('connectionId');
  const token = getCookie("token")
  const role = getCookie("role")

  useEffect(() => {
    if (!connectionIdParam && (!token || !role)) return navigate("/")
    dispatch(patientActions.patientLogin(connectionIdParam))
  }, [])

  useEffect(() => {
    if (!connectionId && (!token || !role)) return
    if (!profile) return navigate("/patient/profile")
    navigate("/patient/posts")
  }, [profile, connectionId])


  return null
}

