import { prefixer } from 'stylis'
import { NavBar } from './components'
import React, { useEffect } from 'react'
import createCache from '@emotion/cache'
import rtlPlugin from 'stylis-plugin-rtl'
import { useDispatch } from 'react-redux'
import { heIL } from '@mui/material/locale'
import { baseActions } from './redux/actions'
import { CacheProvider } from '@emotion/react'
import { Cast, PeopleAltOutlined } from '@mui/icons-material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom'
import {
  AdminLogin, AdminMonitors, MonitorLogin, MonitorSlideShow, AdminUsers, PatientLogin, PatientProfile,
  PatientPosts, PatientPostForm
} from './screens'
import HomeScreen from './screens/HomeScreen'
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { Trans } from '@lingui/macro'
import { LanguageProvider } from './components/LanguageContext'
import NewVersionCheck from './components/NewVersionCheck'

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: "Assistant",
    h3: {
      fontWeight: 600
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    },
  }
},
  heIL
)

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const RTL = ({ children }) => <CacheProvider value={cacheRtl}>{children}</CacheProvider>

const adminItems = [
  {
    name: <Trans>Agree</Trans>,
    link: '/admin/monitors',
    icon: <Cast />
  },
  {
    name: <Trans>Users</Trans>,
    link: '/admin/users',
    icon: <PeopleAltOutlined />
  }
]

const patientNav = [
  {
    name: <Trans>Profile</Trans>,
    link: '/patient/profile',
    icon: <AccountCircleOutlinedIcon />
  },
  {
    name: <Trans>Posts</Trans>,
    link: '/patient/posts',
    icon: <DnsOutlinedIcon />
  }
]

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: 'monitor',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <MonitorLogin />,
      },
      {
        path: "showSlide",
        element: <MonitorSlideShow />
      },
    ]
  },
  {
    path: 'admin',
    element: <NavBar items={adminItems} indexPath='/admin' />,
    children: [
      {
        index: true,
        element: <AdminLogin />
      },
      {
        path: 'monitors',
        element: <AdminMonitors />
      },
      {
        path: 'users',
        element: <AdminUsers />
      }
    ]
  },
  {
    path: 'patient',
    element: <NavBar items={patientNav} indexPath='/patient' /*showHeader={false}*/ />,
    children: [
      {
        index: true,
        element: <PatientLogin />,
      },
      {
        path: "profile",
        element: <PatientProfile />
      },
      {
        path: "posts",
        element: <PatientPosts />
      },
      {
        path: "posts/new",
        element: <PatientPostForm />
      },
      {
        path: "posts/edit",
        element: <PatientPostForm />
      }
    ]
  },
]);

const WindowSize = ({ children }) => {
  const dispatch = useDispatch()

  const updateScreenSize = () => {
    dispatch(baseActions.setScreenSize({ width: window.innerWidth, height: window.innerHeight }))
  }

  useEffect(() => {
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [])
  return <>{children}</>
}

const App = () => {

  return (
    <LanguageProvider>
      <I18nProvider i18n={i18n}>
        <NewVersionCheck />
        <RTL>
          <ThemeProvider theme={theme}>
            <WindowSize>
              <RouterProvider router={router} />
            </WindowSize>
          </ThemeProvider>
        </RTL>
      </I18nProvider>
    </LanguageProvider>
  )
}

export default App;
