import { SmallLogo } from './IconSmall'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useMatch, Outlet, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCookie, setCookie } from '../helpers'
import { BaseButton, GradientButton } from './buttons'
import LogoutIcon from '@mui/icons-material/Logout';
import { adminActions, patientActions } from '../redux/actions'
import { Dialog } from '.'
import { t } from '@lingui/macro'
import { BasicSelect } from './Select'
import { LANGUAGES } from '../enums'
import { useLanguage } from './LanguageContext'

const LanguageSelector = () => {
    const languages = Object.values(LANGUAGES)
    const { language, changeLanguage } = useLanguage()

    return (
        <BasicSelect
            value={language}
            items={languages}
            onChange={changeLanguage}
            placeholder={"Language"}
        />
    )
}

const samePageLinkNavigation = (event) => {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

const NavContent = ({ items = [], isMobile = false }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { hasProfileChanges = false } = useSelector(state => state.patient)
    const [currentTab, setCurrentTab] = useState(getCookie('tab') || 0)
    const [openDialog, setOpenDialog] = useState(false)
    const [navigatingLink, setNavigatingLink] = useState(null)

    const location = useLocation()
    const role = getCookie("role")

    useEffect(() => {
        const [_, path2, path3] = location.pathname.split('/')
        const currentPath = `/${path2}/${path3}`
        const currentIndex = items.findIndex(item => item.link === currentPath)
        setCurrentTab(currentIndex)
    }, [location])

    const handleChange = (event, newTab) => {
        if (event.type !== 'click' || (event.type === 'click' && samePageLinkNavigation(event))) {
            if (hasProfileChanges) return

            setCurrentTab(newTab);
            setCookie('tab', newTab)
        }
    }

    const handleLogout = async () => {
        await dispatch(adminActions.logout())
        navigate("/")
    }

    const handleNavigation = (link, tabIndex) => {
        if (hasProfileChanges && tabIndex !== currentTab) {
            setNavigatingLink(link)
            return setOpenDialog(true)
        }
        navigate(link)
    }

    const handleConfirmDialog = async () => {
        await dispatch(patientActions.saveProfileChanges(true))
        setOpenDialog(false)
        navigate(navigatingLink)
    }

    const handleCloseDialog = async () => {
        setOpenDialog(false)
        navigate(navigatingLink)
        dispatch(patientActions.setProfileHasChanges(false))
    }

    const renderDialog = () => {
        return (
            <Dialog
                title={hasProfileChanges ? t`Profile Changes` : ""}
                open={openDialog}>
                <Typography>{t`Do you want to save the changes you made?`}</Typography>
                <Typography>{t`If you don't save the changes they will be lost`}</Typography>
                <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                    <BaseButton
                        variant="contained"
                        color="error"
                        onClick={handleCloseDialog}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`No`}
                    </BaseButton>
                    <GradientButton
                        variant="contained"
                        onClick={handleConfirmDialog}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Yes`}
                    </GradientButton>
                </Box>
            </Dialog>
        )
    }


    return (
        <Grid container direction={isMobile ? "row" : "column"} sx={{ bottom: 0 }} justifyContent={"space-between"} height={"100%"}>
            {renderDialog()}
            {!isMobile && <Grid item paddingBottom='2rem' paddingInlineStart='2rem' paddingTop='1rem'>
                <SmallLogo />
            </Grid>}
            <Tabs
                value={currentTab}
                onChange={handleChange}
                variant={isMobile ? 'fullWidth' : 'standard'}
                orientation={isMobile ? 'horizontal' : 'vertical'}
                TabIndicatorProps={isMobile ? { sx: { top: 0 } } : {}}
                sx={[{ display: 'flex', flex: 1, '&& .MuiTab-root': { justifyContent: 'start' } }]}
            >
                {items.map((item, index) => (
                    <Tab
                        key={index}
                        value={index}
                        icon={item.icon}
                        label={item.name}
                        onClick={() => handleNavigation(item.link, index)}
                        sx={[currentTab === index && { backgroundColor: '#E8EAFC' }]}
                        iconPosition={isMobile ? "top" : "start"} />
                ))}
            </Tabs>
            {(!isMobile && role === "admin") && <BaseButton variant="outlined" style={{ margin: 10 }} startIcon={<LogoutIcon style={{marginInlineEnd: 5 }} />} onClick={handleLogout}>{t`Logout`}</BaseButton>}
            {(!isMobile && role === "patient") && (
                <Box padding={2}>
                    <LanguageSelector />
                </Box>
            )}
        </Grid>
    )
}

const BaseBar = ({ items = [], showHeader = true }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isMobile } = useSelector(state => state.base)

    const navGridStyle = isMobile ? { marginInline: '1rem' } : { borderInlineEnd: '1px solid #D9D9D9' }
    const role = getCookie("role")

    const handleLogout = async () => {
        await dispatch(adminActions.logout())
        navigate("/")
    }

    return (
        <Grid container direction={isMobile ? "column-reverse" : "row"} height="100vh" flexWrap='nowrap' >
            <Grid item xs={1} sx={navGridStyle} minWidth="12rem" >
                <NavContent items={items} isMobile={isMobile} />
            </Grid>
            <Grid item xs={11} display='flex' flexGrow={1} height='100%'>
                <Outlet />
            </Grid>
            {(isMobile && showHeader) && (
                <Grid item sx={{ marginTop: '2rem', marginInlineStart: '1rem' }}>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <SmallLogo />
                        {(isMobile && role === "admin") &&
                            <BaseButton
                                startIcon={<LogoutIcon />}
                                style={{ fontSize: "1em", marginInlineEnd: 15 }}
                                onClick={handleLogout}
                            >
                                {t`Logout`}
                            </BaseButton>
                        }

                        {(isMobile && role === "patient") &&
                            <Box paddingInline={2} minWidth={150}>
                                <LanguageSelector />
                            </Box>
                        }
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

export const NavBar = ({ items = [], indexPath = '', showHeader = true }) => {
    const isIndex = Boolean(useMatch(indexPath))
    return isIndex ? <Outlet /> : <BaseBar items={items} showHeader={showHeader} />
}
