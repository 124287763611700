import { createSlice } from '@reduxjs/toolkit'


const baseSlice = createSlice({
    name: 'base',
    initialState: {
        isMobile: false,
        screenHeight: 1000,
        screenWidth: 1000
    },
    reducers: {
        setScreenSize: ( state, action ) => {
            const { width, height } = action.payload
            state.screenWidth = width
            state.screenHeight = height
            state.isMobile = width <= 768
        }
    },
})

export const baseActions = baseSlice.actions

export default baseSlice.reducer