import React from 'react'
import { useSelector } from 'react-redux'
import { Box, FormControl, Grid, Typography, Dialog as MuiDialog, DialogTitle, DialogContent } from '@mui/material'
import { SmallLogo, LabelInput, LayoutButton, SearchInput, SearchSelect, GradientButton, TextAreaField } from './index'

const inputTypes = {
    input: (input) => <LabelInput key={input.label}  {...input} />,
    textarea: (input) => <TextAreaField key={input.label} {...input} />
}

export const LoginForm = ({
    title = '',
    inputs = [],
    buttonSx = {},
    buttonText = '',
    onSubmit = () => null
}) => {
    const { isMobile } = useSelector(state => state.base)
    return (
        <Box sx={{
            height: '100vh',
            display: 'flex',
            flex: 5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "2rem"
        }}>
            <SmallLogo style={{ justifyContent: "center" }} />
            <Typography variant={isMobile ? "h5" : "h4"} textAlign='center' maxWidth={isMobile ? '20rem' : '30rem'} sx={{ wordBreak: "break-word" }}>
                {title}
            </Typography>
            <FormControl>
                {inputs.map(({ inputType = "input", ...input }) => inputTypes[inputType](input))}
            </FormControl>
            <GradientButton
                size="large"
                sx={buttonSx}
                variant="contained"
                onClick={onSubmit}>
                {buttonText}
            </GradientButton>
        </Box>
    )
}

export const PatientProfileForm = ({
    inputs = [],
    onSubmit = () => null
}) => {
    const { isMobile } = useSelector(state => state.base)
    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            gap: "3rem"
        }}>
            <FormControl style={{ maxHeight: "calc(100vh - 620px)", ...isMobile && { marginTop: 10, maxHeight: "calc(100vh - 715px)", } }}>
                {inputs.map(({ inputType = "input", ...input }) => inputTypes[inputType](input))}
            </FormControl>
        </Box >
    )
}

export const ScreenLayout = ({
    children,
    buttonSx = {},
    search = null,
    select = null,
    buttonText = '',
    containerSX = {},
    isMobile = false,
    selectItems = [],
    selectPlaceholder = '',
    buttonContainerSx = {},
    buttonAlwaysBelow = false,
    renderButton = () => null,
    onButtonClick = () => null,
    onSearchChange = () => null,
    onStatusChange = () => null,
}) => {
    const layoutButton = <LayoutButton
        text={buttonText}
        isMobile={isMobile || buttonAlwaysBelow}
        onclick={onButtonClick}
        buttonSx={buttonSx}
        containerSx={buttonContainerSx}
    />

    const renderManagement = () => {
        if (!search && !select && buttonAlwaysBelow) return;

        return (
            <Grid item xs={1}>
                <Grid container direction='row' marginTop={isMobile ? '0.5rem' : '1rem'} spacing={1} alignItems="stretch">
                    {search !== null && <Grid item xs={isMobile ? 6 : 4}>
                        <SearchInput
                            search={search}
                            onSearchChange={onSearchChange} />
                    </Grid>}
                    {select !== null && <Grid item xs={isMobile ? 6 : 2}>
                        <SearchSelect
                            value={select}
                            items={selectItems}
                            onChange={onStatusChange}
                            placeholder={selectPlaceholder} />
                    </Grid>}
                    {!isMobile && !buttonAlwaysBelow && <>
                        {layoutButton}
                        {renderButton()}
                    </>}
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container direction='column' spacing={1} flexGrow={1} sx={containerSX}>
            {renderManagement()}
            <Grid item xs={true} display='flex' flexGrow={1} overflow='auto'>
                {children}
            </Grid>
            {(isMobile || buttonAlwaysBelow) && (renderButton() || layoutButton)}
        </Grid>
    )
}

export const Dialog = ({
    children,
    title = '',
    open = false,
    onClose = () => null
}) => (
    <MuiDialog
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { paddingBlockEnd: '1rem', flexGrow: 1 } }}>
        <DialogTitle variant='h5'>{title}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: 'column' }}>
            {children}
        </DialogContent>
    </MuiDialog>
) 