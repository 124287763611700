import adminReducer from './slices/adminSlice'
import { configureStore } from '@reduxjs/toolkit'
import monitorReducer from './slices/monitorSlice'
import baseReducer from './slices/baseSlice'
import patientReducer from './slices/patientSlice'

export default configureStore({
    reducer: {
        base: baseReducer,
        admin: adminReducer,
        monitor: monitorReducer,
        patient: patientReducer,
    }
})