
import { authApi } from '../../api'
import { patientApi } from '../../api/patient'
import { removeCookie, setCookie } from '../../helpers'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const patientLogin = createAsyncThunk('auth/patient', async (connectionId, thunkAPI) => {
    try {
        const { token, patient } = await authApi.patientLogin(connectionId)
        return {  token, patient, connectionId }
    } catch (err) {
        console.log("PatientSlice.patientLogin error", err)
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const saveProfile = createAsyncThunk('patient/profile/save', async (data, thunkAPI) => {
    try {
        const result = await patientApi.saveProfile(data)
        return result
    } catch (err) {
        console.log("PatientSlice.saveProfile error", err)
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const getProfile = createAsyncThunk('patient/profile', async (data, thunkAPI) => {
    try {
        const result = await patientApi.getPatient(data)
        return result
    } catch (err) {
        console.log("PatientSlice.getProfile error", err)
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const getPosts = createAsyncThunk('patient/posts', async (connectionId, thunkAPI) => {
    try {
        const result = await patientApi.getThumbnails(connectionId)
        return result
    } catch (err) {
        console.log("PatientSlice.getPosts error", err)
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const updatePost = createAsyncThunk('patient/posts/update', async (data, thunkAPI) => {
    try {
        const result = await patientApi.updatePost(data)
        return result
    } catch (err) {
        console.log("PatientSlice.updatePost error", err)
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const publishPost= createAsyncThunk('patient/posts/publish', async (data, thunkAPI) => {
    try {
        const result = await patientApi.publishPost(data)
        return result
    } catch (err) {
        console.log("PatientSlice.publishPost error", err)
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const deletePost= createAsyncThunk('patient/image', async (id, thunkAPI) => {
    try {
        const result = await patientApi.deletePost(id)
        return result
    } catch (err) {
        console.log("PatientSlice.deletePost error", err)
        return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
})

const patientSlice = createSlice({
    name: 'patient',
    initialState: {
        hasProfileChanges: false,
        saveProfileChanges: false,
        profile: null,
        images: [],
        monitors: [],
        connectionId: '',
        error: "",
    },
    reducers: {
        updateImageList: (state, action) => {
            state.images = [...action.payload]
        },
        setProfileHasChanges: (state, action) => {
            if (state.hasProfileChanges && action.payload) return
            state.hasProfileChanges = action.payload
        },
        saveProfileChanges: (state, action) => {
            state.saveProfileChanges = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(patientLogin.fulfilled, (state, action) => {
            const { token, patient, connectionId } = action.payload
            const { name = '', family = '', profession = '', hobbies = '', landmarks = null } = patient || {}
            state.error = ''
            state.profile = name || family || profession || hobbies || landmarks ? patient : null;
            state.connectionId = connectionId
            if (!!action.payload) {
                setCookie('token', token)
                setCookie('role', "patient")
                setCookie('connectionId', connectionId)
            }
        })
        builder.addCase(patientLogin.rejected, (state, action) => {
            console.log("patientSlice.patientLogin(): action", action.payload)
            state.error = action.payload
        })
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.error = ''
            state.profile = action.payload
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            if (action.payload === "Forbidden" || action.payload === "Unauthorized") {
                removeCookie("token")
                removeCookie("role")
                removeCookie("connectionId")
            }
            state.error = action.payload
        })
        builder.addCase(saveProfile.fulfilled, (state, action) => {
            state.error = ''
            state.hasProfileChanges = false
            state.saveProfileChanges = false
            state.profile = action.payload
        })
        builder.addCase(saveProfile.rejected, (state, action) => {
            state.error = action.payload
        })
        builder.addCase(publishPost.fulfilled, (state, action) => {
            state.error = ''
        })
        builder.addCase(publishPost.rejected, (state, action) => {
            state.error = action.payload
        })
        builder.addCase(getPosts.fulfilled, (state, action) => {
            state.error = ''
            state.images = [...action.payload]
        })
        builder.addCase(getPosts.rejected, (state, action) => {
            if (action.payload === "Forbidden") {
                removeCookie("token")
            }
            state.error = action.payload
        })
        builder.addCase(deletePost.fulfilled, (state, action) => {
            state.error = ''
        })
        builder.addCase(deletePost.rejected, (state, action) => {
            state.error = action.payload
        })
    }
})

export const patientActions = {
    patientLogin,
    saveProfile,
    getProfile,
    publishPost,
    getPosts,
    updatePost,
    deletePost,
    ...patientSlice.actions
}

export default patientSlice.reducer