import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { t } from "@lingui/macro"

export const SearchSelect = ({
    items = [],
    value = '',
    placeholder = '',
    onChange = () => null
}) => {
    return (
        <Select
            size="small"
            displayEmpty
            value={value}
            sx={[{ flexGrow: 1, display: 'flex' }, !value && { color: '#8C8C8C' }]}
            onChange={event => onChange(event.target.value)}>
            <MenuItem value="" sx={{ color: '#8C8C8C' }}>{!value ? placeholder : t`Clean up`}</MenuItem>
            {items.map(item => <MenuItem key={item.value} value={item.value}>{item.text || item.value}</MenuItem>)}
        </Select>
    )
}

export const BasicSelect = ({
    items = [],
    value = '',
    placeholder = '',
    onChange = () => null
}) => {
    return (
        <FormControl fullWidth>
            <InputLabel>{placeholder}</InputLabel>
            <Select
                size="small"
                displayEmpty
                value={value}
                label={placeholder}
                sx={[{ flexGrow: 1, display: 'flex' }, !value && { color: '#8C8C8C' }]}
                onChange={event => onChange(event.target.value)}>
                {items.map(item => <MenuItem key={item.value} value={item.value}>{item.text || item.value}</MenuItem>)}
            </Select>
        </FormControl>
    )
}