
export const LANGUAGES = {
    en: { value: "en", text: "English" },
    he: { value: "he", text: "Hebrew" },
    ar: { value: "ar", text: "Arabic" }
}

export const RTL_LANGUAGES = [
    "he", 
    "ar"
]