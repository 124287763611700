import { Container } from '@mui/material'
import { getCookie } from '../../helpers'
import { LoginForm } from '../../components'
import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { monitorActions } from "../../redux/actions.js"
import { t } from '@lingui/macro'

export const MonitorLogin = () => {
    const dispatch = useDispatch()
    const [ displayId, setDisplayId ] = useState('')
    const { deviceId, error = '' } = useSelector(state => state.monitor)
    const navigate = useNavigate()
    useEffect(() => {
        if(getCookie('token')) dispatch(monitorActions.getMonitor())
    }, [dispatch])

    useEffect(() => {
        if(deviceId && getCookie('token')) navigate('/monitor/showSlide')
    }, [deviceId, navigate])
    
    
    const onSubmit = async () => {
        dispatch(monitorActions.monitorLogin(displayId))
    }

    const inputs = [{
        label: t`Screen Number`,
        value: displayId,
        helperText: error,
        error: !!error,
        onChange: value => setDisplayId(value),
        inputSx: { width: '30rem' },
        labelStyle: { fontSize: '1.3em' }
    }]

    return (
        <Container maxWidth="xl">
            <LoginForm
                inputs={inputs}
                title={t`Please enter the screen number to connect to the system`}
                buttonText={t`Login`}
                buttonSx={{ width: '30rem', fontSize: '1.3em' }}
                onSubmit={onSubmit}
             />
        </Container>
    )
}

