import React, { useRef, useState } from "react"
import { Search as SearchIcon } from '@mui/icons-material'
import { Box, FormLabel, InputAdornment, TextField, Typography, styled } from "@mui/material"
import { Trans } from "@lingui/macro"

export const SearchInput = ({
    search = '',
    onSearchChange = () => null,
}) => {
    return (
        <Trans render={word => (
            <TextField
                size="small"
                value={search}
                placeholder={word.translation}
                sx={{ flexGrow: 1, display: 'flex' }}
                onChange={event => onSearchChange(event.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        )}>Search
        </Trans>
    )
}

export const LabelInput = ({
    helperText = '',
    error = false,
    label = '',
    value = '',
    inputSx = {},
    type = 'text',
    labelStyle = {},
    InputProps = {},
    onChange = () => null,
    placeholder = ''
}) => (
    <>
        <FormLabel style={{ color: 'black', fontWeight: 500, ...labelStyle }}>
            {label}
        </FormLabel>
        <TextField
            type={type}
            placeholder={placeholder}
            size="small"
            sx={inputSx}
            value={value}
            onChange={event => onChange(event.target.value)}
            InputProps={{ style: { fontSize: '1.1em' }, ...InputProps }}
            helperText={helperText}
            error={error}
            fullWidth
        />
    </>
)

export const TextAreaField = ({
    helperText = '',
    error = false,
    label = '',
    value = '',
    inputSx = {},
    type = 'text',
    labelStyle = {},
    InputProps = {},
    onChange = () => null,
    fullWidth = false,
    inputContainerStyle = {},
    mainContainerStyle = {},
    placeholder = '',
    maxLength = 0
}) => {
    const textFieldRef = useRef(null);
    const [onFocus, setOnFocus] = useState(false)
    const handleClick = () => textFieldRef.current.focus();

    const handleChange = (val) => {
        if (!maxLength || (maxLength && val.length <= maxLength)) {
            onChange(val)
        }
    }

    const characterLeft = maxLength && maxLength - value.length;
    return (
        <Box onClick={handleClick} style={{ width: "100%", height: "100%", ...mainContainerStyle }}>
            <FormLabel style={{ color: 'black', fontWeight: 500, ...labelStyle }}>
                {label}
            </FormLabel>
            <Box
                style={{
                    border: onFocus ? "2px solid #0076cf" : "1px solid #BFBFBF",
                    borderRadius: "5px",
                    overflow: "auto",
                    height: "100%",
                    '& .MuiInputBase-root::before': { borderBottom: 'unset' },
                    ...inputContainerStyle
                }}>
                <TextField
                    inputRef={textFieldRef}
                    placeholder={placeholder}
                    type={type}
                    size="small"
                    multiline
                    sx={inputSx}
                    value={value}
                    onChange={event => handleChange(event.target.value)}
                    InputProps={{ style: { fontSize: '1.1em', padding: 10 }, ...InputProps }}
                    helperText={helperText}
                    error={error}
                    variant={"standard"}
                    fullWidth={fullWidth}
                    onFocus={() => setOnFocus(true)}
                    onBlur={() => setOnFocus(false)}
                />

            </Box>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                {maxLength ? <Typography style={{ marginInlineEnd: 10, color: "gray", textAlign: "right" }}>{<Trans>characters</Trans>}{`: ${characterLeft}`}</Typography> : null}
            </Box>
        </Box>
    )
}

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});