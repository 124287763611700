import { Grid, Typography } from '@mui/material'
import { ReactComponent as LogoSmall } from '../assets/subscriptions.svg' 
import { Trans } from '@lingui/macro'

export const SmallLogo = ({style = {}}) => (
    <Grid container direction="row" sx={style}>
        <Grid bottom={0}  marginInlineEnd={1} >
            <Typography fontFamily="Fredoka" variant='h6' fontWeight={700} fontSize="1.5em" >{<Trans>Empathy</Trans>}</Typography>
        </Grid>
        <Grid bottom={0} marginTop={0.5}>
            <LogoSmall  />
        </Grid>
    </Grid>
)