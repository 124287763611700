import { api } from '../helpers/index'

const monitorLogin = async deviceId => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post('auth/monitor', { deviceId })
        if( !response.data || !response.data.token ) return reject()
        resolve(response.data.token)
    } catch (err) {
        reject(err)
    }
})

const patientLogin = async connectionId => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post('auth/patient', { connectionId })
        if( !response.data || !response.data.token ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const adminLogin = async data => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post('auth/admin', data)
        if( !response.data || !response.data.token ) return reject()
        resolve(response.data.token)
    } catch (err) {
        reject(err)
    }
})

export const authApi = {
    adminLogin,
    monitorLogin,
    patientLogin
}