import { api } from "../helpers"

const verifyVersion = async () => new Promise(async (resolve, reject) => {
    try {
        const response = await api.get(`/verifyVersion`)
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

export const commonApi = {
    verifyVersion
}