import { api } from '../helpers/index'

const getMonitor = async () => new Promise(async (resolve, reject) => {
    try {
        const response = await api.get('monitors/')
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const getAllMonitors = async () => new Promise( async (resolve, reject) => {
    try {
        const response = await api.get('monitors/all/')
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const createMonitor = async ( description ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.post('monitors/create/', { description })
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const updateMonitor = async ( data ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.post('monitors/update/', data)
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const deleteMonitor = async ( data ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.post('monitors/delete', data)
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const clearMonitor = async ( data ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.post('monitors/clear', data)
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const disconnectMonitor = async ( data ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.post('monitors/disconnect', data)
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const reloadMonitor = async ( data ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.post('monitors/reload', data)
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

export const monitorApi = {
    getMonitor,
    clearMonitor,
    createMonitor,
    updateMonitor,
    deleteMonitor,
    getAllMonitors,
    disconnectMonitor,
    reloadMonitor
}