import { useEffect, useRef } from "react";
import { useNotification } from "./NotificationContext";
import { t } from "@lingui/macro";
import { bitbucketCommitId, versionCheckInterval } from "../config";
import { commonApi } from "../api/common";

const NewVersionCheck = () => {
  const intervalCheckRef = useRef(null);
  const { showNotification } = useNotification();

  const compareVersions = async () => {
    console.log("bitbucketCommitId", bitbucketCommitId)
    const result = await commonApi.verifyVersion();
    if (!bitbucketCommitId) return true;
    if (
      !!result.version
      && result.version !== bitbucketCommitId
    ) return true;
    return false;
  };

  const clearIntervalCheck = () => {
    intervalCheckRef.current && clearInterval(intervalCheckRef.current);
    intervalCheckRef.current = null;
  };

  const handleVersionCheck = async () => {
    intervalCheckRef.current = setInterval(async () => {
      if (!intervalCheckRef.current) return;
      const _hasUpdate = await compareVersions();
      if (!_hasUpdate) return;
      showNotification(t`A newer version is available. Please reload your browser`, "info", false, window.location.reload)
      clearIntervalCheck(); // show only once
    }, versionCheckInterval);
  }

  useEffect(() => {
    handleVersionCheck()
    return clearIntervalCheck;
  }, []);

  return null;
};

export default NewVersionCheck;
