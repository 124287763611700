import React, { useState, useEffect } from 'react'
import { Button, Grid, IconButton, styled } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export const GradientButton = styled(Button)`
    background-image: linear-gradient(267.82deg, #0058FF 10.67%, #262479 145.42%)!important;
    text-transform: capitalize;
  `

export const DashedButton = styled(Button)`
background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23377CFFFF' stroke-width='2' stroke-dasharray='14%2c 15' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
border-radius: 2px;
overflow: hidden;
`

export const BaseButton = styled(Button)`
text-transform: capitalize;
`

export const ShowPasswordButton = ({ 
    show = false, 
    onClick = () => null 
}) => {
    const [isHover, setIsHover] = useState(false)

    useEffect(() => onClick(), [isHover])
    return (
        <IconButton
            aria-label="toggle password visibility"
            onClick={ onClick }
            onMouseEnter={() => !show ? setIsHover(!isHover) : null}
            onMouseLeave={() => isHover ? setIsHover(!isHover) : null}>
                { show ? <Visibility /> : <VisibilityOff /> }
        </IconButton>
    )
}

export const LayoutButton = ({
    text = '',
    isMobile = false,
    onclick = () => null,
    containerSx = null,
    buttonSx = null
}) => {
    return (
        <Grid item xs={ isMobile ? 1 : 2 } display='flex' sx={containerSx}>
            <GradientButton
                onClick={onclick}
                sx={[{ flexGrow: 1, display: 'flex', color: '#fff', fontSize: '1em', ...buttonSx }, isMobile && {marginBlock: '1rem' }]}>
                    { text }
            </GradientButton>
        </Grid>
    )
}
