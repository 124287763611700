import { getCookie } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { adminActions } from '../../redux/actions'
import { BaseButton } from '../../components/buttons'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteOutlineOutlined as DeleteIcon, EditOutlined as EditIcon } from '@mui/icons-material'
import { Container, Typography, FormControl, DialogActions } from '@mui/material'
import { Dialog, GradientButton, LabelInput, ScreenLayout, Table, useNotification } from '../../components'
import { t } from '@lingui/macro'

const AddDialogContent = ({
    title = '',
    email = '',
    password = '',
    buttonTitle = '',
    onClick = () => null,
    onEmailChange = () => null,
    onPasswordChange = () => null
}) => (
    <>
        <Typography>
            {title}
        </Typography>
        <FormControl >
            <LabelInput
                value={email}
                label={t`Email`}
                onChange={onEmailChange}
                labelStyle={{ marginBlockStart: '0.5rem' }}
            />
            <LabelInput
                label={t`Password`}
                value={password}
                type='password'
                onChange={onPasswordChange}
                labelStyle={{ marginBlockStart: '0.5rem' }}
            />
        </FormControl>
        <GradientButton
            variant='contained'
            onClick={onClick}
            sx={{ marginBlockStart: '1rem' }}>
            {buttonTitle}
        </GradientButton>
    </>
)


export const AdminUsers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showNotification } = useNotification()
    const [search, setSearch] = useState('')
    const [addEmail, setAddEmail] = useState('')
    const [addPassword, setAddPassword] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
    // const [openEditDialog, setOpenEditDialog] = useState(false)
    const [editUser, setEditUser] = useState(null)
    const [deleteAdmin, setDeleteAdmin] = useState(null)
    const { isMobile } = useSelector(state => state.base)
    const { email, admins } = useSelector(state => state.admin)


    useEffect(() => {
        if (!getCookie('token'))
            navigate('/admin')
        else if (email)
            dispatch(adminActions.getAllAdmins())
        else
            dispatch(adminActions.getAdmin())
    }, [email, dispatch, navigate])

    const isDataCorrect = (isEdit = false) => {
        if((!isEdit && (!addEmail || !addPassword) )|| (isEdit && (!addEmail && !addPassword))){
            showNotification(t`Missing email or password`, "error")
            return false
        }
        if(!!addPassword && addPassword.length < 8){
            showNotification(t`The password must be at least 8 characters long`, "error")
            return false
        }
        if(!!addEmail && (!addEmail.includes('@') || !addEmail.includes('.'))){
            showNotification(t`The email is invalid`, "error")
            return false
        }

        return true
    }

    const onSubmit = async () => {
        if (!isDataCorrect()) return

        const result = await dispatch(adminActions.createAdmin({ email: addEmail, password: addPassword }))
        if (result?.error) {
            showNotification(t`An error occurred while creating a user`, "error")
            return
        }
        showNotification(t`New user successfully added`, "success")
        setAddEmail('')
        setAddPassword('')
        setOpenDialog(false)
    }

    const onDialogClose = () => {
        setEditUser(null)
        setOpenDialog(false)
        setDeleteAdmin(null)
    }

    const onAdminDelete = async () => {
        const result = await dispatch(adminActions.deleteAdmin(deleteAdmin._id))
        if (result?.error) {
            showNotification(t`An error occurred while deleting the user`, "error")
            return
        }
        dispatch(adminActions.getAllAdmins())
        showNotification(t`User deleted successfully`, "success")
        onDialogClose()
    }
    const dataStructure = [{ header: t`Email`, key: 'email' }]

    const onEditUser = (user) => {
        setAddEmail((user && user.email) || '')
        setEditUser(user)
    }

    const onEditSubmit = async () => {
        if (!isDataCorrect(true)) return
        const result = await dispatch(adminActions.updateAdmin({id: editUser._id ,email: addEmail, password: addPassword }))
        if (result?.error) {
            showNotification(t`An error occurred while updating the user`, "error")
            return
        }
        showNotification(t`The user has been updated successfully`, "success")
        setAddEmail('')
        setAddPassword('')
        setEditUser(null)
    }

    const actions = [
        {
            text: t`Edit`,
            Icon: EditIcon,
            onClick: item => { onEditUser(item) }
        },
        {
            text: t`Delete`,
            Icon: DeleteIcon,
            onClick: item => { setDeleteAdmin(item) }
        }
    ]


    return (
        <Container maxWidth="xl" sx={{ marginTop: isMobile ? '1rem' : '2rem', display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'column' }} >
            <Typography variant={isMobile ? 'h4' : 'h3'}>{t`User Management`}</Typography>
            <ScreenLayout
                search={search}
                isMobile={isMobile}
                buttonText={t`Add New User`}
                onButtonClick={() => setOpenDialog(true)}
                onSearchChange={value => setSearch(value)}>
                <Table
                    data={admins}
                    search={search}
                    actions={actions}
                    isMobile={isMobile}
                    dataStructure={dataStructure}
                />
            </ScreenLayout>
            <Dialog
                open={openDialog || !!deleteAdmin || !!editUser}
                title={openDialog ? t`Add New User` : !!editUser ? t`Edit User` : !!deleteAdmin ? t`Delete User` : ''}
                onClose={onDialogClose}>
                {!!deleteAdmin ?
                    <>
                        <Typography>
                            {t`Are you sure you want to delete the user ${deleteAdmin.email}?`}
                        </Typography>
                        <DialogActions>
                            <BaseButton variant="contained" color="error" onClick={onDialogClose}>{t`Cancel`}</BaseButton>
                            <GradientButton variant="contained" autoFocus onClick={onAdminDelete}>{t`Delete`}</GradientButton>
                        </DialogActions>
                    </>
                    : openDialog || !!editUser ? <AddDialogContent
                        title={openDialog ? t`Please enter details of the new user` : t`Here you can update the email address and password of the user`}
                        buttonTitle={t`Submit`}
                        email={addEmail}
                        password={addPassword}
                        onClick={openDialog ? onSubmit : onEditSubmit}
                        onEmailChange={value => setAddEmail(value)}
                        onPasswordChange={value => setAddPassword(value)} />
                        : <></>}
            </Dialog>
        </Container>
    )
} 