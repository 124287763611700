import axios from "axios";
import { getCookie } from './index'
import { serverUrl } from "../config";


export const api = axios.create({
    baseURL: serverUrl,
    timeout: 5000,
    // headers: {
    //     "ngrok-skip-browser-warning":"any"
    // }
})

api.interceptors.request.use( config => {
    const token = getCookie('token')
    if (!!token) config.headers.Authorization = `Bearer ${token}`
    return config
})

