import { api } from '../helpers/index'

const getAdmin = async () => new Promise(async (resolve, reject) => {
    try {
        const response = await api.get('admins/')
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const getAllAdmins = async () => new Promise( async (resolve, reject) => {
    try {
        const response = await api.get('admins/all/')
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const createAdmin = async ( { email, password } ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.post('admins/create/', { email, password })
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const updateAdmin = async ( { id, email, password } ) => new Promise( async (resolve, reject) => {
    try {
        const response = await api.put('admins/update/', { id, email, password })
        if( !response.data ) return reject()
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

const deleteAdmin = async (id) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.delete(`admins/${id}`)
        resolve(response.data)
    } catch (err) {
        reject(err)
    }
})

export const adminApi = {
    getAdmin,
    createAdmin,
    updateAdmin,
    deleteAdmin,
    getAllAdmins
}