import { Box, Container, Typography } from "@mui/material"
import { SmallLogo } from "../components";
import { GradientButton } from "../components/buttons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getCookie } from "../helpers";
import { t } from "@lingui/macro";

const HomeScreen = () => {
  const navigate = useNavigate()
  const { isMobile } = useSelector(state => state.base)
  const token = getCookie("token")
  const role = getCookie("role")

  useEffect(() => {
    if (token && role) {
      navigate(`/${role}`)
    }
  }, [token, role, navigate])

  const labelFontSize = isMobile ? '1em' : '1.3em'
  const currentWidth = isMobile ? '22rem' : '30rem'

  return (
    <Container>
      <Box sx={{
        height: '100vh',
        display: 'flex',
        flex: 5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2rem"
      }}>
        <SmallLogo style={{ justifyContent: "center" }} />
        <Typography variant={isMobile ? "h5" : "h4"} textAlign='center' maxWidth={isMobile ? '18rem' : '25rem'} sx={{ wordBreak: "break-word" }}>
          {t`Welcome to the Empathy System. Where would you like to connect?`}
        </Typography>
        <GradientButton
          size="large"
          style={{ width: currentWidth, fontSize: labelFontSize }}
          variant="contained"
          onClick={() => navigate("/admin")}>
          {t`Management System`}
        </GradientButton>
        <GradientButton
          size="large"
          style={{ width: currentWidth, fontSize: labelFontSize }}
          variant="contained"
          onClick={() => navigate("/monitor")}>
          {t`Display Screen`}
        </GradientButton>
      </Box>
    </Container>
  )
}

export default HomeScreen;