import { Alert, Box, Slide, Snackbar, Typography } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { styled } from '@mui/system';
import { Dialog } from './layouts';
import { GradientButton } from './buttons';
import { t } from '@lingui/macro';

const CustomAlert = styled(Alert)(({ theme }) => ({
  '& .MuiAlert-icon': {
    paddingInlineStart: theme.spacing(1),
    paddingTop: theme.spacing(1.1)
  },
}))

const CustomSnackbar = ({ message, severity, onClose, duration = 5000 }) => {
  const otherProps = {
    ...duration && { autoHideDuration: 5000 }
  }

  return (
    <Snackbar
      open={true}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      {...otherProps}
      TransitionComponent={Slide}
    >
      <CustomAlert variant="filled" onClose={onClose} severity={severity}>
        <Typography paddingInlineEnd={2} paddingInlineStart={.5} paddingY={0}>{message}</Typography>
      </CustomAlert>
    </Snackbar>
  );
};

const NotificationContext = createContext();
export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null)

  const showNotification = (message, severity, duration) => { //error, warning, info, success
    setNotification({ message, severity, duration });
  };

  const showDialogMessage = (message) => {
    setDialogMessage(message);
  };

  const hideNotification = () => {
    setNotification(null);
  };

  const closeDialog = () => {
    showDialogMessage(null)
  }

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification, showDialogMessage }}>
      {children}
      {notification && (
        <CustomSnackbar
          message={notification.message}
          severity={notification.severity}
          onClose={hideNotification}
          duration={notification?.duration}
        />
      )}
      {dialogMessage && (
        <Dialog title={t`You have been disconnected from the system`} onClose={closeDialog} open>
          <Typography>{dialogMessage}</Typography>
          <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
            <GradientButton
              variant="contained"
              color="error"
              onClick={closeDialog}
              style={{ marginInlineEnd: 5 }}
            >
              {t`Ok`}
            </GradientButton>
          </Box>
        </Dialog>
      )}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};