import { CardMedia } from "@mui/material"

const { useState, useEffect } = require("react")
const { patientApi } = require("../api/patient")

export const ImageStream = ({ id, styles = {} }) => {
  const [image, setImage] = useState(null)

  const getStreamImage = async () => {
    try {
      const result = await patientApi.streamImage(id)
      setImage(result)
    } catch (error) {
      console.log("Failed to stream image, error:", error)
    }
  }

  useEffect(() => {
    getStreamImage()
  }, [id])

  if (!image) return null;

  return (
    <CardMedia
      image={image}
      alt={id}
      style={{ width: "100%", height: "100%", textAlign: "center", backgroundSize: "contain", ...styles }}
      obje
    />
  )
}