export const calculateMillisecondsUntil = (hour, minute = 0) => {
    const now = new Date();
    const targetTime = new Date();
    targetTime.setHours(hour, minute, 0, 0);

    // If the target time is earlier in the day, schedule it for the next day
    if (targetTime <= now) {
        targetTime.setDate(now.getDate() + 1);
    }

    return targetTime - now;
};