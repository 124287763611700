import { Box, Button, Grid, FormControl, Typography, CardMedia } from '@mui/material'
import { getCookie } from '../../helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, GradientButton, ImageStream, LabelInput, ScreenLayout, TextAreaField, useNotification } from '../../components'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { BaseButton, DashedButton } from '../../components/buttons'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { VisuallyHiddenInput } from '../../components/inputs'
import { patientActions } from '../../redux/actions'
import useDrivePicker from 'react-google-drive-picker'
import { googleDriveClientId, googleDriveDevKey } from '../../config'
import { t, Trans } from '@lingui/macro'

export const PatientPostForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openPicker] = useDrivePicker();
  const location = useLocation()
  const { showNotification, showDialogMessage } = useNotification();
  const { isMobile } = useSelector(state => state.base)
  const { error } = useSelector(state => state.patient)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const connectionId = getCookie("connectionId")
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  const [imagePickerOptionOpen, setImagePickerOptionOpen] = useState(false);
  const inputFileRef = useRef(null)
  const post = location.state?.post;

  useEffect(() => {
    if (!post) return;
    const { metadata = null } = post || {}
    const { title, description } = metadata || {}
    setTitle(title)
    setDescription(description)
    setSelectedImage(post._id)
  }, [post])

  useEffect(() => {
    if (!getCookie('token') || error === "Forbidden") {
      showDialogMessage(t`You have been disconnected from the system. Please scan the QR code again.`)
      return navigate("/")
    }
  }, [error])

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", title)
    formData.append("description", description)
    formData.append("connectionId", connectionId)
    formData.append("image", fileImage)
    let result = null;
    if (post) {
      formData.append("id", post._id)
      result = await dispatch(patientActions.updatePost(formData))
    } else {
      result = await dispatch(patientActions.publishPost(formData))
    }

    if (result?.error) {
      const text = post ? t`updating` : t`publishing`
      showNotification(t`An error occurred while ${text} the image`, "error")
      return
    }
    showNotification(post ? t`The image has been updated` : t`The image has been published`, "success")
    navigate("/patient/posts")
  }

  const handleCancel = () => {
    navigate("/patient/posts")
  }

  const handleImage = (event) => {
    const file = event.target.files[0];
    setFileImage(file)

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
    inputFileRef.current.value = null
  }

  const handleOpenPicker = () => {
    openPicker({
      clientId: googleDriveClientId,
      viewId: "DOCS",
      developerKey: googleDriveDevKey,
      viewMimeTypes: "image/jpeg,image/png",
      showUploadView: false,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      callbackFunction: async (data) => {
        try {
          if (!data || data.action === 'cancel') return;

          const { docs = [] } = data || {}
          const imageFile = docs.length ? docs[0] : null
          if (!imageFile || !imageFile?.id) return;
          const imageUrl = `https://lh3.googleusercontent.com/d/${imageFile?.id}=s1000?authuser=0`
          const blob = await fetch(imageUrl).then((res) => res.blob())
          setSelectedImage(imageUrl)
          setFileImage(blob)
        } catch (error) {
          console.log({ error })
          showNotification(t`Error uploading image to Google Drive, make sure the image is public.`, "error")
        }
      },
    })
  }

  const labelFontSize = isMobile ? '1em' : '1.3em'
  const currentWidth = isMobile ? "calc(100% - 7px)" : "30rem";
  const buttonSx = { width: currentWidth, fontSize: labelFontSize, marginBottom: 2 }

  const renderImage = () => {
    if (!selectedImage) {
      return <Typography color="primary" sx={{ textTransform: "none" }} ><Trans>Click to add an image</Trans></Typography>
    }

    if (!fileImage && post) return <ImageStream id={post._id} />

    return (
      <CardMedia
        image={selectedImage}
        alt="imagepost"
        style={{ width: "100%", height: "100%", textAlign: "center", backgroundSize: "contain" }}
        obje
      />

    )
  }

  const renderUploadField = () => {
    const buttonText = selectedImage ? <Trans>Delete Image</Trans> : <Trans>Add Image</Trans>;
    const buttonIcon = selectedImage ? <DeleteOutlineIcon /> : <DownloadOutlinedIcon />;
    const buttonAction = selectedImage ? () => setSelectedImage(null) : () => setImagePickerOptionOpen(true)
    const uploadAreaAction = selectedImage ? () => null : () => setImagePickerOptionOpen(true)

    return (
      <>
        <DashedButton
          onClick={uploadAreaAction}
          style={{
            width: currentWidth,
            minHeight: "200px",
            padding: 1,
          }}>
          <Box style={{
            height: "100%", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden"
          }}>
            {renderImage()}
          </Box>
        </DashedButton>
        <Button
          size="large"
          sx={buttonSx}
          variant="outlined"
          onClick={buttonAction}
          style={{ textTransform: "capitalize", marginTop: 10 }}
          startIcon={buttonIcon}
        >
          {buttonText}
        </Button>
      </>
    )
  }

  const titleInput = {
    label: <Trans>Title</Trans>,
    placeholder: <Trans>Father at a family dinner</Trans>,
    value: title,
    onChange: value => setTitle(value),
    labelStyle: { fontSize: labelFontSize },
    inputSx: { width: currentWidth }
  }

  const renderButton = () => {
    return (
      <Grid display={"flex"} flexDirection={"column"} alignItems={isMobile ? "" : "center"}>
        <GradientButton
          size="large"
          variant="contained"
          sx={[{ textTransform: "capitalize", marginTop: '1rem', marginInline: '.6rem' }, !isMobile && { maxWidth: currentWidth }]}
          onClick={handleSubmit}
          fullWidth={!isMobile && true}
        >
          {post ? <Trans>Save</Trans> : <Trans>Publish</Trans>}
        </GradientButton>
        <Button
          size="large"
          variant="outlined"
          sx={[{ textTransform: "capitalize", marginBlock: '1rem', marginInline: '.6rem' }, !isMobile && { maxWidth: currentWidth }]}
          onClick={handleCancel}
          fullWidth={!isMobile && true}
        >
          <Trans>Cancel</Trans>
        </Button>
      </Grid>
    )
  }

  const renderImagePickerOption = () => {
    if (!imagePickerOptionOpen) return;

    return (
      <Dialog
        title={<Trans>Select Image From</Trans>}
        open={imagePickerOptionOpen}
        onClose={() => setImagePickerOptionOpen(false)}>
        <Box display={"flex"} flexDirection="column" justifyContent={"center"} mt={2} gap={1}>
          <BaseButton
            variant="outlined"
            onClick={() => {
              setImagePickerOptionOpen(false);
              inputFileRef.current.click()
            }}
            style={{ marginInlineEnd: 5 }}
            size="large"
          >
            <Trans>Device</Trans>
          </BaseButton>
          {/* <BaseButton
            variant="outlined"
            onClick={() => {
              setImagePickerOptionOpen(false);
              handleOpenPicker()
            }}
            style={{ marginInlineEnd: 5 }}
          >
            Google Drive
          </BaseButton> */}
        </Box>
      </Dialog>
    )
  }

  return (
    <ScreenLayout
      buttonAlwaysBelow={true}
      renderButton={renderButton}
      containerSX={{ marginInline: '1rem', marginTop: 2 }}
    >
      {renderImagePickerOption()}
      <VisuallyHiddenInput ref={inputFileRef} type="file" accept="image/*" onChange={handleImage} />
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: isMobile ? "space-between" : "center", alignItems: "center", flexDirection: "column", marginTop: 2 }}>
        <Box style={{ width: currentWidth, height: "100%", marginBottom: 40, display: "flex", flexDirection: "column" }}>
          {renderUploadField()}
          <FormControl>
            <Trans render={word => (
              <LabelInput
                key={titleInput}
                label={t`Title`}
                placeholder={word.translation}
                value={title}
                onChange={value => setTitle(value)}
                labelStyle={{ fontSize: labelFontSize }}
                inputSx={{ width: currentWidth }} />
            )}>
              Father on reserve duty
            </Trans>
          </FormControl>
          <TextAreaField
            fullWidth
            label={<Trans>Description</Trans>}
            placeholder={""}
            value={description}
            onChange={setDescription}
            InputProps={{ disableUnderline: true }}
            inputContainerStyle={{
              width: currentWidth,
              height: "90%",
              minHeight: '5rem',
              maxHeight: isMobile ? "calc(100vh - 672px)" : "calc(100vh - 540px)",
              '& .MuiInputBase-root::before': { borderBottom: 'unset' }
            }}
            maxLength={500}
          />
        </Box>
      </Box >
    </ScreenLayout>
  )
}
