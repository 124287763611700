import moment from "moment/moment"
import { getCookie } from "../../helpers"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { patientApi } from "../../api/patient"
import { patientActions } from "../../redux/actions"
import { BaseButton } from "../../components/buttons"
import { useDispatch, useSelector } from "react-redux"
import { Box, CardMedia, Grid, Typography } from "@mui/material"
import { Dialog, GradientButton, ScreenLayout, useNotification } from "../../components"
import { Trans, t } from "@lingui/macro"

export const PatientPosts = () => {
  const { showDialogMessage, showNotification } = useNotification()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isMobile } = useSelector(state => state.base)
  const { images, error } = useSelector(state => state.patient)
  const [postId, setPostId] = useState(null)
  const labelFontSize = isMobile ? '1em' : '1.3em'
  const currentWidth = isMobile ? "22rem" : "30rem";

  const styles = {
    mainBox: {
      flex: 1,
      display: 'flex',
      justifyContent: isMobile ? "space-between" : "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "2rem",
      height: "100%"
    },
    noPostBox: {
      height: "60%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: isMobile ? "flex-start" : "center"
    },
    gradientButton: {
      width: currentWidth,
      fontSize: labelFontSize,
      marginBottom: 2,
    },
    postItemBox: {
      borderRadius: 2,
      boxShadow: "0px 1px 5px 0px #0000001A",
      display: "flex",
      flexDirection: "column",
    },
    postItemImage: {
      borderRadius: 2,
      aspectRatio: 1
    },
    postItemFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: 8
    },
    title: {
      fontSize: 16,
      fontWeight: "500"
    },
    description: {
      lineHeight: 1.2,
      display: "-webkit-box",
      WebkitLineClamp: "8",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }
  }

  const getPost = () => {
    const connectionId = getCookie("connectionId")
    dispatch(patientActions.getPosts(connectionId))
  }

  useEffect(() => {
    if (!getCookie('token') || error === "Forbidden") {
      showDialogMessage(t`You are not connected, please scan the QR code again`)
      return navigate("/")
    }
    if (error) return
    getPost()
  }, [error])

  const handleDialogClose = () => setPostId(null)

  const handleConfirmDelete = async () => {
    const newImageList = images.filter(image => image._id !== postId)
    await dispatch(patientActions.updateImageList(newImageList))
    const result = await dispatch(patientActions.deletePost(postId))
    if (result?.error) {
      showNotification(t`An error occurred while deleting the image`, "error")
      return
    }
    showNotification(t`The image was deleted successfully`, "success")

    handleDialogClose()
    getPost()
  }

  const handleEditPost = async (item) => {
    navigate("/patient/posts/edit", { state: { post: item } })
  }

  const RenderPostItem = ({ item }) => {
    const [img, setImg] = useState(null)
    const { _id, metadata, uploadDate } = item || {}
    const { title, description } = metadata || {}

    const getStreamImage = async (imageId) => {
      try {
        const result = await patientApi.streamImage(imageId)
        setImg(result)
      } catch (error) {
        console.log("Failed to stream image, error:", error)
      }
    }

    useEffect(() => {
      getStreamImage(_id)
    }, [])

    const info = !!description || !!title
    return (
      <Grid item xs={info ? 12 : 6}>
        <Box style={styles.postItemBox}>
          <Grid container onClick={() => handleEditPost(item)}>
            {info &&
              <Grid item xs={6} padding={1}>
                <Typography component="div" style={styles.title}>{title}</Typography>
                <Typography variant="body2" component="div" style={styles.description}>
                  {description}
                </Typography>
              </Grid>
            }
            <Grid item xs={info ? 6 : 12} padding={1} paddingInlineStart={info ? 1.5 : 1}>
              <CardMedia
                component="img"
                style={styles.postItemImage}
                image={img}
                alt={"image"}
              />
            </Grid>
          </Grid>
          <Box style={styles.postItemFooter}>
            <BaseButton
              size="small"
              color="error"
              variant="outlined"
              style={{ fontSize: 16 }}
              onClick={() => setPostId(_id)}>
              <Trans>Delete</Trans>
            </BaseButton>
            <Typography>{`${moment(uploadDate).format("DD.MM.YYYY")}`}</Typography>
          </Box>
        </Box>
      </Grid>
    )
  }

  const renderPosts = () => {
    if (!images || !images.length) {
      return (
        <Box sx={styles.noPostBox}>
          <Typography maxWidth={isMobile ? '20rem' : '30rem'} fontSize={24} lineHeight={1.2}>
            <Trans>Click on</Trans> <strong>{<Trans>Add a new image</Trans>}</strong> {<Trans>to add an image</Trans>}
          </Typography>
        </Box>
      )
    }

    return (
      <Grid
        container
        spacing={1}
        marginTop={2}
        maxHeight={"calc(100vh - 250px)"}
        overflow={"auto"}>
        {images.map(img => <RenderPostItem key={img._id} item={img} />)}
      </Grid>
    )

  }

  const renderDialog = () => {
    return (
      <Dialog title={<Trans>Delete Image</Trans>} open={!!postId} onClose={handleDialogClose}>
        <Typography><Trans>Are you sure you want to delete this image?</Trans></Typography>
        <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
          <BaseButton
            variant="contained"
            color="error"
            onClick={handleDialogClose}
            style={{ marginInlineEnd: 5 }}
          >
            {<Trans>Cancel</Trans>}
          </BaseButton>
          <GradientButton
            variant="contained"
            onClick={handleConfirmDelete}
            style={{ marginInlineEnd: 5 }}
          >
            <Trans>Delete</Trans>
          </GradientButton>
        </Box>
      </Dialog>
    )
  }

  return (
    <ScreenLayout
      isMobile={isMobile}
      buttonAlwaysBelow={true}
      buttonText={<Trans>Add a new image</Trans>}
      containerSX={{marginInline: '1rem'}}
      buttonContainerSx={{ justifyContent: "center" }}
      buttonSx={!isMobile && { maxWidth: currentWidth }}
      onButtonClick={() => navigate("/patient/posts/new")}
    >
      {renderDialog()}
      <Box sx={styles.mainBox}>
        {renderPosts()}
      </Box >
    </ScreenLayout>
  )
}
