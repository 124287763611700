import { api } from '../helpers/index'

const saveProfile = async (data) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.post('patient/profile', data)
    if (!response.data) return reject()
    resolve(response.data)
  } catch (err) {
    reject(err)
  }
})

const getPatient = async (data) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.get('patient/profile', { params: data })
    if (!response.data) return reject()
    resolve(response.data)
  } catch (err) {
    console.error("patient.getPatient(): error response", err)
    reject(err)
  }
})

const getPosts = async (connectionId) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.get(`patient/images/${connectionId}`)
    if (!response.data) return reject()
    resolve(response.data)
  } catch (err) {
    reject(err)
  }
})

const getThumbnails = async (connectionId) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.get(`patient/thumbnails/${connectionId}`)
    if (!response.data) return reject()
    resolve(response.data)
  } catch (err) {
    reject(err)
  }
})

const updatePost = async (data) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.post(`patient/post/update`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 30000,
    })
    if (!response.data) return reject()
    resolve(response.data)
  } catch (err) {
    reject(err)
  }
})

const publishPost = async (data) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.post('patient/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 30000,
    })
    if (!response.data) return reject()
    resolve(response.data)
  } catch (err) {
    reject(err)
  }
})

const deletePost = async (id) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.delete(`patient/image/${id}`)
    if (!response.data) return reject()
    resolve(response.data)
  } catch (err) {
    reject(err)
  }
})

const streamImage = async (id) => new Promise(async (resolve, reject) => {
  try {
    const response = await api.get(`patient/image/${id}`, {
      responseType: 'blob', 
      timeout: 30000,
    })
    if (!response || !response.data) reject()
    const blob = new Blob([response.data]);
    const url = URL.createObjectURL(blob);
    resolve(url)
  } catch (err) {
    reject(err)
  }
})

export const patientApi = {
  saveProfile,
  getPatient,
  publishPost,
  getPosts,
  updatePost,
  streamImage,
  deletePost,
  getThumbnails
}